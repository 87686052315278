<template>
    <div style="background-color: #BABBBF;width: 100%;width: 100vw;height: 100vh;min-width: 1030px;min-height: 910px;">
        <!-- width: 100vw;height: 100vh;min-width: 1030px;min-height: 910px; -->
        <div style="height: 1px;"></div>
        <div class="faCla">
            <div class="fa1">
                <div style="background-color: #fff;">
                    <div style="display: inline-block;vertical-align: sub;width: 8%;">
                        <img src="@/assets/wxf.png" alt="" width="21px" style="margin-left: 5px;">

                    </div>
                    <div style="display: inline-block;width: 80%;">
                        <div class="title" style="text-align: center;">

                            <p style="line-height: 17px;text-align: center;font-weight: 500;" v-if="hao3Fla"
                                @click="hao3Fla = false">{{
                                    hao3 || '赵群' }}
                            </p>
                            <p v-else>
                                <el-input v-model="hao3" @keydown.enter="hao3Fla = true" @change="hao3Fla = true"
                                    style="width: 30%;"></el-input>
                            </p>
                        </div>
                    </div>
                    <div
                        style="display: inline-block;vertical-align: -webkit-baseline-middle;width: 10%;    margin-left: 5px;">
                        <img src="@/assets/wxsd.png" alt="" width="21px">
                    </div>
                </div>
            </div>

            <div class="fa2">
                <div style="height: 1px;"></div>
                <div style="height: 575px;overflow: hidden scroll;" class="funCla">

                    <!-- 1111 -->

                    <div class="fa3">
                        <div style="text-align: right;height: 100%;position: relative;">
                            <div style="display: inline-block;width: 85%;vertical-align: top;">


                                <div class="fa4"
                                    style="background: #A9EA7A;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="hao7Fla"
                                        @click="hao7Fla = false">{{ hao7 || '请青青' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao7" @keydown.enter="hao7Fla = true"
                                            @change="hao7Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>

                            </div>

                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wwr.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-right: 8px;background: #A9EA7A; ">
                        </div>
                    </div>



                    <!-- 2222 -->
                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/wwz.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-left: 10px;background: #fff;">
                            <div class=" bubble_arrow_left" style="top: 13px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;margin-left: 9px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="hao8Fla"
                                        @click="hao8Fla = false">{{ hao8 || '好' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao8" @keydown.enter="hao8Fla = true"
                                            @change="hao8Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;margin-top: 5px;padding: 2px 9px;color:#918b8b;border-radius: 5px;">

                            <p style="line-height: 17px;text-align: left;" class="bbtx" v-if="hao9Fla"
                                @click="hao9Fla = false">{{
                                    hao9 || '昨天 23:09' }}
                            </p>
                            <p v-else>
                                <el-input v-model="hao9" @keydown.enter="hao9Fla = true" @change="hao9Fla = true"
                                    style="width: 50%;"></el-input>
                            </p>

                        </span>
                    </div>


                    <!-- 333333 -->
                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/wwz.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-left: 10px;background: #fff;">
                            <div class=" bubble_arrow_left" style="top: 13px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;margin-left: 9px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="hao1Fla"
                                        @click="hao1Fla = false">{{ hao1 || '6214833802236359' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao1" @keydown.enter="hao1Fla = true"
                                            @change="hao1Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <!-- 4444 -->
                    <div class="fa3">
                        <div style="text-align: right;height: 100%;position: relative;">
                            <div style="display: inline-block;width: 85%;vertical-align: top;">


                                <div class="fa4"
                                    style="background: #A9EA7A;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="inputFla"
                                        @click="inputFla = false">{{ inputText }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="inputText" @keydown.enter="inputFla = true"
                                            @change="inputFla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>

                            </div>

                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wwr.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-right: 8px;background: #A9EA7A; ">
                        </div>
                    </div>


                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;margin-top: 5px;padding: 2px 9px;color:#918b8b;border-radius: 5px;">

                            <p style="line-height: 17px;text-align: left;" class="bbtx" v-if="hao10Fla"
                                @click="hao10Fla = false">{{
                                    hao10 || '昨天 23:15' }}
                            </p>
                            <p v-else>
                                <el-input v-model="hao10" @keydown.enter="hao10Fla = true" @change="hao10Fla = true"
                                    style="width: 50%;"></el-input>
                            </p>

                        </span>
                    </div>



                    <!-- 55555555 -->
                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;position: relative;">

                            <div style="display: inline-block;width: 85%;vertical-align: top;">

                                <div class="fa4"
                                    style="background: #A9EA7A;color: #000; max-width: 75%;margin-right: 11px;padding: 9px 11px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="inputShopFla"
                                        @click="inputShopFla = false">{{ inputShop ||
                                            '.账号13783435837@163.com郑州邀购电子商务有限公司' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="inputShop" @keydown.enter="inputShopFla = true"
                                            @change="inputShopFla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>
                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wwr.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-right: 8px;background: #A9EA7A; ">

                        </div>
                    </div>


                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;margin-top: 5px;padding: 2px 9px;color:#918b8b;border-radius: 5px;">

                            <p style="line-height: 17px;text-align: left;" class="bbtx" v-if="hao11Fla"
                                @click="hao11Fla = false">{{
                                    hao11 || '昨天 23:15' }}
                            </p>
                            <p v-else>
                                <el-input v-model="hao11" @keydown.enter="hao11Fla = true" @change="hao11Fla = true"
                                    style="width: 50%;"></el-input>
                            </p>

                        </span>
                    </div>


                    <!-- 6666 -->
                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/wwz.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-left: 10px;background: #fff;">
                            <div class=" bubble_arrow_left" style="top: 13px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;margin-left: 9px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="hao2Fla"
                                        @click="hao2Fla = false">{{ hao2 || '账号64965287@gq.com 赵艳' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao2" @keydown.enter="hao2Fla = true"
                                            @change="hao2Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <!-- 7777 -->
                    <div class="fa3">
                        <div style="text-align: left;height: 100%;position: relative;">
                            <img src="@/assets/wwz.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-left: 10px;background: #fff;">
                            <div class=" bubble_arrow_left" style="top: 13px;"></div>
                            <div style="display: inline-block;width: 85%;vertical-align: top;">
                                <div class="fa4"
                                    style="background: #fff;color: #000; max-width: 77%;margin-right: 11px;padding: 9px 11px;margin-left: 9px;">
                                    <p style="line-height:17px;text-align: left;" v-if="hao6Fla"
                                        @click="hao6Fla = false">{{ hao6 || '咱姐准备买房呢！还差点钱你给咱姐转五万块钱' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao6" @keydown.enter="hao6Fla = true"
                                            @change="hao6Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>



                    <div style="display: flex;align-items: center;justify-content: center;">
                        <span
                            style="text-align: center;font-size: 12px;margin-top: 5px;padding: 2px 9px;color:#918b8b;border-radius: 5px;">

                            <p style="line-height: 17px;text-align: left;" class="bbtx" v-if="hao12Fla"
                                @click="hao12Fla = false">{{
                                    hao12 || '00:56' }}
                            </p>
                            <p v-else>
                                <el-input v-model="hao12" @keydown.enter="hao12Fla = true" @change="hao12Fla = true"
                                    style="width: 50%;"></el-input>
                            </p>

                        </span>
                    </div>


                    <!-- 77777777 -->
                    <div class="fa3" style="margin: 12px 0px;">
                        <div style="text-align: right;height: 100%;position: relative;">

                            <div style="display: inline-block;width: 85%;vertical-align: top;">

                                <div class="fa4"
                                    style="background: #A9EA7A;color: #000; max-width: 75%;margin-right: 11px;padding: 9px 11px;">
                                    <p style="line-height: 17px;text-align: left;" v-if="hao5Fla"
                                        @click="hao5Fla = false">{{ hao5 ||
                                            '转账风控了，我找支付宝解冻下' }}
                                    </p>
                                    <p v-else>
                                        <el-input v-model="hao5" @keydown.enter="hao5Fla = true"
                                            @change="hao5Fla = true" style="width: 100%;"></el-input>
                                    </p>
                                </div>
                            </div>
                            <div class="bubble_arrow_right"></div>
                            <img src="@/assets/wwr.jpg" alt="" srcset=""
                                style="width: 36px;height: 36px;;vertical-align: top;border-radius: 3px;margin-right: 8px;background: #A9EA7A; ">

                        </div>
                    </div>





                </div>




                <div class="son1"
                    style="display: flex;width: 740px;height: 50px;background: #DFDFDF;width: 100%;justify-content: center; align-items: center;">

                    <!-- <div style="width: 100%;margin-top: 5px;height: 42px;background-color: #DFDFDF;position: relative;"
                        class="Son1"> -->

                    <div style="width: 8%;">
                        <img src="@/assets/lb.png" alt="" width="23px" style="margin-top: 3px;">
                    </div>

                    <el-input v-model="shuru" placeholder="" style="width: 67%;"></el-input>
                    <div style="width: 10%;">
                        <img src="@/assets/xll2.png" alt="" width="22px" style="margin-top: 3px;margin-left: 7px;">
                    </div>
                    <div style="width: 10%;">
                        <img src="@/assets/ttg.png" alt="" style="margin-top: 3px;margin-left: 5px;" width="25px">
                    </div>

                    <!-- <div class="son2">
                            发送
                        </div> -->
                    <!-- 
                        <div style="position:absolute;bottom: 0px;text-align: center;width: 100%;color: #BFBFBF;">
                            <img src="@/assets/yvyvvy.png" alt="" srcset="" style="vertical-align: sub;">
                            七鱼提供服务支持
                        </div> -->
                    <!-- </div> -->


                    <!-- <div style="margin-top: 5px;">
                            <img src="@/assets/xiao.png" alt="" srcset="" style="width: 34px;">
                            <img src="@/assets/jia.png" alt="" srcset="" style="width: 34px;">
                        </div> -->

                </div>

            </div>




        </div>
    </div>
    </div>
</template>
<script>
export default {
    name: 'kefu',
    data() {
        return {
            time: this.formatDate2(new Date),
            times: this.formatDate3(new Date),
            inputText: '6214833802236359',
            inputShop: '',
            inputFla: true,
            inputShopFla: true,
            shuru: '',
            num: Math.floor(Math.random() * 10000),
            hao1: '',
            hao1Fla: true,
            hao2: '',
            hao2Fla: true,
            hao3: '',
            hao3Fla: true,
            hao5: '',
            hao5Fla: true,
            hao6: '',
            hao6Fla: true,
            hao7: '',
            hao7Fla: true,
            hao8: '',
            hao8Fla: true,
            hao9: '',
            hao9Fla: true,
            hao10: '',
            hao10Fla: true,
            hao11: '',
            hao11Fla: true,
            hao12: '',
            hao12Fla: true,
        }
    },

    mounted() {
    },

    methods: {
        formatDate2(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes() - 1; //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            // return year + "-" + (month <= 9 ? '0' + month : month) + "-" + (date <= 9 ? '0' + date : date) + " " + (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
            return (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)

        },
        formatDate3(now) {
            let year = now.getFullYear(); //取得4位数的年份
            let month = now.getMonth() + 1; //取得日期中的月份，其中0表示1月，11表示12月
            let date = now.getDate(); //返回日期月份中的天数（1到31）
            let hour = now.getHours(); //返回日期中的小时数（0到23）
            let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
            let seconds = now.getSeconds(); //返回日期中的秒数（0到59）
            return (hour <= 9 ? '0' + hour : hour) + ":" + (minute <= 9 ? '0' + minute : minute)
            //  + ":" + seconds;
        },
    }
}
</script>

<style lang="less" scoped>
.faCla {
    width: 348px;
    margin: 80px auto;
    height: 672px;
    max-height: 800px;
    overflow: hidden;
    // border-radius: 11px;
    // min-height: 820px;
}

.fa1 {
    height: 46px;
    line-height: 50px;
    background: #fff;
    font-size: 15px;
    // padding-left: 25px;
    color: #000;
    // border-radius: 10px 10px 0px 0px;

}

.fa2 {
    background: #EDEEF0;
    height: 100%;
    position: relative;
}

.fa3 {
    min-height: 36px;
    line-height: 36px;
    margin-top: 10px;
}

.fa4 {
    display: inline-block;
    font-size: 14px;
    width: auto;
    border-radius: 3px;

}

.sa1 {
    color: #000;
    background-color: #fff;
    padding: 5px 12px;
    border-radius: 23px;
    font-size: 13px;
    margin-left: 11px;
    border: 1px solid rgb(249 104 104);
    height: 28px;
    line-height: 17px;
    margin-top: 8px;
}


.son1 /deep/ .el-input--small .el-input__inner {
    height: 36px;
}

// .Son1 /deep/ .el-input__inner {
//     // background: #fff;
//     color: #8e939e;
//     border: 1px solid #fff;
// }

// // .Son1 /deep/ .el-input .is-disabled {
// //     background: #F5F7FA;
// //     color: blue;
// //     border: 1px solid #fff;

// // }
// .Son1 /deep/input::-webkit-input-placeholder {
//     color: #9094AF;
//     // font-size: 15px;
// }

.bubble_arrow_right {

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 6px solid #A9EA7A;
    right: 49px;
    position: absolute;
    top: 13px;
    content: "";

}

.bubble_arrow_left {
    position: absolute;
    top: 13px;
    content: "";
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 6px solid #fff;
    left: 49px;

}

.funCla {
    background: url(../../assets/bjj.jpg) no-repeat center center;
    background-size: cover;
}


.funCla::-webkit-scrollbar {
    display: none;
}

.son3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;


}

.son2 {
    position: absolute;
    bottom: 27px;
    color: rgb(255, 255, 255);
    height: 27px;
    line-height: 27px;
    width: 64px;
    text-align: center;
    font-size: 15px;
    background: rgb(251, 164, 164);
    right: 6px;
    right: 12px;
    letter-spacing: 2px;
}

.bbtx {
    background: rgb(162, 162, 162, .7);
    color: rgb(0, 0, 0);
    padding: 1px 5px 0px 5px;
    border-radius: 4px;
    font-size: 11px;
}
</style>
